export const opdRxTemplateStringExperimental = `(patient, hospital, ref) => {
    return React.createElement("div", { className: "print-content" },
        // Header Section
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "flex items-center gap-4 w-full" },
                React.createElement("img", {
                    src: hospital?.hospitalLogo2Blob,
                    alt: "Medical Symbol",
                    style: {
                        width: "100px",
                        height: "100px",
                        objectFit: "contain"
                    }
                }),
                React.createElement("div", { className: "flex-1 text-center" },
                    React.createElement("h1", { 
                        className: "text-[#000080] text-4xl font-bold mb-1",
                        style: { fontFamily: "'Brush Script MT', cursive" }
                    }, 
                        hospital?.doctorName || "Dr. Shiv Narayan Singh"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "M.S. (Gen. Surgery), M.C.H. (G.I. Surgery)"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "F.M.A.S. (Fellow of Minimal Access Surgeons of India Given by AMASI)"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "Assistant Professor (Dept. of Surgery)"
                    ),
                    React.createElement("div", { className: "text-sm" },
                        "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE & HOSPITAL, GAYA"
                    )
                ),
                React.createElement("img", {
                    src: hospital?.hospitalLogo2Blob,
                    alt: "Medical Symbol",
                    style: {
                        width: "100px",
                        height: "100px",
                        objectFit: "contain"
                    }
                })
            ),
            React.createElement("div", { className: "flex justify-end mb-2 mt-4" },
                React.createElement("div", { className: "text-sm" }, 
                    "Date: " + new Date(patient?.createdAt).toLocaleDateString("en-IN")
                )
            )
        ),

        // Patient Details Section - Left Column
        React.createElement("div", { 
            style: {
                position: 'absolute',
                top: '55mm',
                left: '15mm',
                width: '100mm',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
            }
        },
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Patient Name :"),
                patient?.patient?.name || ''
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Guardian Name :"),
                patient?.guardianName || ''
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Consultant Dr. :"),
                patient?.doctor?.name || ''
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Department :"),
                patient?.department || ''
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Address :"),
                patient?.patient?.address || ''
            )
        ),

        // Patient Details Section - Right Column
        React.createElement("div", { 
            style: {
                position: 'absolute',
                top: '55mm',
                left: '110mm',
                width: '85mm',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                paddingLeft:'10mm'
            }
        },
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "UHID/Reg No :"),
                patient?.registrationNumber || ''
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Reg. Date :"),
                new Date(patient?.createdAt).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' ,hour12: true})
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Valid For :"),
                // format(addDays(new Date(patient?.createdAt?.split('T')[0]).toLocaleString(), 13), 'dd-MMM-yyyy') || ''
                new Date(new Date(patient?.createdAt).setDate(new Date(patient?.createdAt).getDate() + 13)).toLocaleString("en-IN", { year: 'numeric', month: '2-digit', day: '2-digit'})
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Age/Sex :"),
                \`\${patient?.patient?.age || ''} Years / \${patient?.patient?.gender || ''}\`
            ),
            React.createElement("div", { className: "mb-2" },
                React.createElement("span", { style: { fontWeight: 'bold', display: 'inline-block', width: '35mm' } }, "Mobile No :"),
                patient?.patient?.contactNumber || ''
            )
        ),

        // BP and Weight Section - Below both columns
        React.createElement("div", { 
            style: {
                position: 'absolute',
                top: '85mm',
                left: '15mm',
                width: '180mm',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                textAlign: 'right',
                paddingRight: '20mm'
            }
        },
           
            React.createElement("div", null,
                React.createElement("span", { style: { fontWeight: 'bold' } }, "Weight(kgs) : "),
                patient?.vitals?.weight ? \`\${patient?.vitals?.weight} \` : ''
            ),
             React.createElement("div", { style: { marginBottom: '2mm' } },
                React.createElement("span", { style: { fontWeight: 'bold' } }, "BP : "),
                patient?.vitals?.bloodPressure || ''
            ),
        )
    );
}`;
export const opdRxTemplateStringExperimental1 = `(patient, hospital, ref) => {
    return React.createElement("div", { className: "print-content" },
        // Patient Details Section - Left Column
        React.createElement("div", { 
            style: {
                position: 'absolute',
                top: '60mm',
                left: '15mm',
                width: '100mm',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                lineHeight: '1.3'
            }
        },
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Patient Name :"),
                patient?.patient?.name || ''
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Guardian Name :"),
                patient?.guardianName || ''
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Consultant Dr. :"),
                patient?.doctor?.name || ''
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Department :"),
                patient?.department || ''
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Address :"),
                patient?.patient?.address || ''
            )
        ),

        // Patient Details Section - Right Column
        React.createElement("div", { 
            style: {
                position: 'absolute',
                top: '60mm',
                left: '110mm',
                width: '85mm',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                paddingLeft: '10mm',
                lineHeight: '1.3'
            }
        },
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "UHID/Reg No :"),
                patient?.registrationNumber || ''
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Reg. Date :"),
                new Date(patient?.createdAt).toLocaleString("en-IN", { 
                    year: 'numeric', 
                    month: '2-digit', 
                    day: '2-digit', 
                    hour: '2-digit', 
                    minute: '2-digit',
                    hour12: true
                })
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Valid For :"),
                new Date(new Date(patient?.createdAt).setDate(new Date(patient?.createdAt).getDate() + 13))
                    .toLocaleString("en-IN", { 
                        year: 'numeric', 
                        month: '2-digit', 
                        day: '2-digit'
                    })
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Age/Sex :"),
                \`\${patient?.patient?.age || ''} Years / \${patient?.patient?.gender || ''}\`
            ),
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold', 
                        display: 'inline-block', 
                        width: '35mm' 
                    } 
                }, "Mobile No :"),
                patient?.patient?.contactNumber || patient?.contactNumber || ''
            )
        ),

        // BP and Weight Section - Below both columns
        React.createElement("div", { 
            style: {
                position: 'absolute',
                top: '85mm',
                left: '15mm',
                width: '180mm',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                textAlign: 'right',
                paddingRight: '20mm'
            }
        },
            React.createElement("div", null,
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold' 
                    } 
                }, "Weight(kgs) : "),
                patient?.vitals?.weight ? \`\${patient?.vitals?.weight} \` : ''
            ),
            React.createElement("div", { 
                style: { 
                    marginBottom: '2mm' 
                } 
            },
                React.createElement("span", { 
                    style: { 
                        fontWeight: 'bold' 
                    } 
                }, "BP : "),
                patient?.vitals?.bloodPressure || ''
            )
        )
    );
}`;
export const opdRxTemplateStringExperimental2 = `(patient, hospital, ref) => {
    const renderField = (label, value, suffix = '') => {
        if (!value) return null;
        return React.createElement("div", { className: "flex gap-2" },
            React.createElement("span", { className: "font-bold min-w-[100px]" }, label),
            React.createElement("span", null, suffix ? \`\${value} \${suffix}\` : value)
        );
    };

    // Create vitals section only if any vitals exist
    const renderVitals = () => {
        const hasVitals = patient?.vitals?.bloodPressure || patient?.vitals?.weight || patient?.vitals?.temperature;
        if (!hasVitals) return null;

        return React.createElement("div", { className: "flex flex-wrap gap-4" },
            patient?.vitals?.bloodPressure && renderField("BP", patient.vitals.bloodPressure, "mmHg"),
            patient?.vitals?.temperature && renderField("Temp", patient.vitals.temperature, "°F"),
            patient?.vitals?.weight && renderField("Weight", patient.vitals.weight, "kg")
        );
    };

    return React.createElement("div", { className: "print-content p-5" },
        // Header Section
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "flex items-center gap-4 w-full" },
                React.createElement("img", {
                    src: hospital?.hospitalLogo2Blob,
                    alt: "Medical Symbol",
                    style: {
                        width: "100px",
                        height: "100px",
                        objectFit: "contain"
                    }
                }),
                React.createElement("div", { className: "flex-1 text-center" },
                    React.createElement("h1", { 
                        className: "text-[#000080] text-4xl font-bold mb-1",
                        style: { fontFamily: "'Brush Script MT', cursive" }
                    }, 
                        hospital?.doctorName || "Dr. Shiv Narayan Singh"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "M.S. (Gen. Surgery), M.C.H. (G.I. Surgery)"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "F.M.A.S. (Fellow of Minimal Access Surgeons of India Given by AMASI)"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "Assistant Professor (Dept. of Surgery)"
                    ),
                    React.createElement("div", { className: "text-sm" },
                        "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE & HOSPITAL, GAYA"
                    )
                ),
                React.createElement("img", {
                    src: hospital?.hospitalLogo2Blob,
                    alt: "Medical Symbol",
                    style: {
                        width: "100px",
                        height: "100px",
                        objectFit: "contain"
                    }
                })
            ),
            React.createElement("div", { className: "flex justify-between items-center mb-2 mt-4" },
                React.createElement("div", { className: "text-sm font-bold" }, 
                    patient?.registrationNumber ? \`UHID/Reg No: \${patient.registrationNumber}\` : ""
                ),
                React.createElement("div", { className: "text-sm" }, 
                    "Date: " + new Date(patient?.createdAt).toLocaleDateString("en-IN")
                )
            )
        ),

        // Patient Details Section - Grid Layout
        React.createElement("div", { 
            style: {
               
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '12px',
                padding: '15px',
                backgroundColor: '#f8f8f8',
                borderRadius: '8px',
                border: '1px solid #e0e0e0',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
            }
        },
            React.createElement("div", { className: "grid gap-3" },
                renderField("Name", patient?.patient?.name),
                renderField("Age/Sex", patient?.patient?.age && patient?.patient?.gender ? 
                    \`\${patient.patient.age} Years / \${patient.patient.gender}\` : null
                ),
                renderField("Guardian", patient?.guardianName)
            ),
            React.createElement("div", { className: "grid gap-3" },
                renderField("Mobile", patient?.patient?.contactNumber),
                renderField("Address", patient?.patient?.address),
                renderVitals()
            )
        )
    );
}`;

export const opdRxTemplateStringExperimental3 = `(patient, hospital, ref) => {
    const renderField = (label, value,valueundersocre=true,suffix = '') => {
        return React.createElement("div", { className: "flex gap-2" },
            React.createElement("span", { className: "font-bold min-w-[100px] " }, label),
            React.createElement("span", null, value ? (suffix ? \`\${value} \${suffix}\` : value) : valueundersocre?"________________":null)
        );
    };

    return React.createElement("div", { className: "print-content p-5" },
        // Header Section with Dr. REEMA KUMARI details
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "flex items-center gap-4 w-full" },
                React.createElement("img", {
                    src: hospital?.hospitalLogo2Blob,
                    alt: "Medical Symbol",
                    style: {
                        width: "100px",
                        height: "100px",
                        objectFit: "contain"
                    }
                }),
                React.createElement("div", { className: "flex-1 text-center font-bold" },
                    React.createElement("h1", { 
                        className: "text-[#000080] text-4xl mb-1",
                        style: { fontFamily: "'Brush Script MT', cursive" }
                    }, 
                        "Dr. REEMA KUMARI"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "Professor"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "Department of Obs/Gynae"
                    ),
                    React.createElement("div", { className: "text-sm" },
                        "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE & HOSPITAL, GAYA"
                    ),
                    React.createElement("div", { className: "text-sm mb-1" },
                        "Ex. Senior Resident | GIMS Departement of Reproductive Biology, Patna"
                    )
                ),
                React.createElement("img", {
                    src: hospital?.hospitalLogo2Blob,
                    alt: "Medical Symbol",
                    style: {
                        width: "100px",
                        height: "100px",
                        objectFit: "contain"
                    }
                })
            )
        ),

      
        // Patient Details Section
        React.createElement("div", { 
            style: {
                padding: '0 15px',
                display: 'grid',
                gap: '12px',
                marginTop: '20px',
                marginBottom:"40px"
            }
        },
            // Name and Date Section
            React.createElement("div", { className: "grid gap-3" },
                React.createElement("div", { className: "flex justify-between w-full" },
                    renderField("Name", patient?.patient?.name),
                    renderField("Reg. No", patient?.registrationNumber),
                    renderField("Date", patient?.bookingDate ? new Date(patient?.bookingDate).toLocaleDateString("en-IN") : null)
                ),
                React.createElement("div", { className: "flex justify-between w-full" },
                    renderField("Address", patient?.patient?.address),
                    renderField("Age/Sex", patient?.patient?.age && patient?.patient?.gender ? 
                        \`\${patient.patient.age} Years / \${patient.patient.gender}\` : null
                    ),
                    renderField("Late Date", "")
                )
            ),

            // Medical History Grid
            React.createElement("div", { 
                
                style: {
                    display:"flex",
                    justifyContent:"space-between",
                    borderTop: "2px solid #e0e0e0",
                    paddingTop: "12px"
                }
            },
               


                //left column
                React.createElement("div", { className: " flex  flex-col justify-between" },
                    renderField("M/H", ""),
                    renderField("O/H", ""),
                ),
                React.createElement("div", { className: " flex  flex-col gap-5" },
                    renderField("BP", patient?.vitals?.bloodPressure, "mmHg"),
                    renderField("Weight", patient?.vitals?.weight, "kg"),
               
               
                    renderField("Bowel bladder", ""),
                    renderField("CHEST CVS", "")
                ),
                
            )

           
        ),
        
    
     React.createElement("div", { className: "pl-[15px] pt-[80px]  flex flex-col  gap-5" },
                renderField("Medical History", "",valueundersocre=false),
                renderField("Surgical History", "",valueundersocre=false),
                renderField("Drug History", "",valueundersocre=false),
                renderField("Allergies", "",valueundersocre=false),
                renderField("Family History", "",valueundersocre=false)
            )
    );
}`;

export const opdRxTemplateStringDefault2 = `(patient, hospital, ref) => {
    return React.createElement("div", { className: "print-content" },
        
        React.createElement("div", { 
          style: {
            position: 'absolute',
            left: '35mm',
            top: '54mm',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif',
          }
        }, patient?.patient?.name || ''),
  
        React.createElement("div", { 
          style: {
            position: 'absolute',
            left: '180mm',
            top: '54mm',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif'
          }
        }, patient?.bookingDate ? patient?.bookingDate.split('T')[0]?.split('-').reverse().join('/') : ''),
  
        React.createElement("div", { 
          style: {
            position: 'absolute',
            left: '150mm',
            top: '54mm',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif'
          }
        }, \`\${patient?.patient?.age || ''} / \${patient?.patient?.gender?.[0] || ''}\`)
  
       
       
    );
  }`;


 